<template>
  <v-container style="max-width:1200px;">
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8"
        >
        <v-card flat style="border-radius:8px;border: 1px solid #AD145733;">
          <v-card-title
            class="py-1 pl-0"
            style="font-size:24px;color:#AD1457;border-bottom:1px solid #AD1457;"
          >
            <v-btn icon color="#AD1457"
                @click="goBack" class="mr-2">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
              {{car && car._id?'Thông Tin Xe':'Tạo Xe Mới'}}
            <v-spacer />
          </v-card-title>
          <v-card-text 
            v-if="displayDeptAfterFilter"
            class="pa-4"
            :class="{'px-2': $vuetify.breakpoint.smAndDown}">
            <div
              style="width:100%;font-size:15px;"
            >

              <!-- Room name -->
              <div style="mt-2">
                Tên Xe *:
              </div>
              <div>
                <v-text-field
                ref="name"

                  flat
                  dense
                  v-model="car.name"
                  :rules="[rules.required]"
                  color="teal"
                  :clearable = "$vuetify.breakpoint.smAndDown"
                  outlined filled
                  single-line
                  hide-details
                  placeholder=""
                ></v-text-field>
              </div>

              <!-- car type -->
              <!-- <div class="mt-2">
                Loại Xe:
              </div>
              <div>
                <v-text-field
                  flat
                  dense
                  v-model="car.type"
                  :rules="[]"
                  color="teal"
                  :clearable = "$vuetify.breakpoint.smAndDown"
                  outlined filled
                  single-line
                  hide-details
                  placeholder=""
                ></v-text-field>
              </div> -->

              <!--num of seat -->
              <div class="mt-2">
                Biển số xe:
              </div>

              <div>
                <v-text-field
                ref="plate"
                  flat
                  dense
                  v-model="car.plate"
                  :rules="[rules.required]"
                  color="teal"
                  :clearable = "$vuetify.breakpoint.smAndDown"
                  outlined filled
                  single-line
                  hide-details
                  placeholder=""
                ></v-text-field>
              </div>

              <!--num of seat -->
              <div class="mt-2">
                Số lượng chổ ngồi:
              </div>

              <div>
                <v-text-field
                  flat
                  dense
                  v-model="car.num_of_seat"
                  :rules="[]"
                  color="teal"
                  :clearable = "$vuetify.breakpoint.smAndDown"
                  outlined filled
                  single-line
                  hide-details
                  placeholder=""
                ></v-text-field>
              </div>

              <!--Phone -->
              <div class="mt-2">
                Số điện thoại:
              </div>

              <div>
                <v-text-field
                  flat
                  dense
                  v-model="car.phone"
                  :rules="[]"
                  color="teal"
                  :clearable = "$vuetify.breakpoint.smAndDown"
                  outlined filled
                  single-line
                  hide-details
                  placeholder=""
                ></v-text-field>
              </div>

              <!--owner info -->
              <div class="mt-2">
                Chủ Xe:
              </div>

              <div>
                <v-text-field
                  flat
                  dense
                  v-model="car.owner"
                  :rules="[]"
                  color="teal"
                  :clearable = "$vuetify.breakpoint.smAndDown"
                  outlined filled
                  single-line
                  hide-details
                  placeholder=""
                ></v-text-field>
              </div>

              <!-- image -->
              <div class="py-1 mt-2">
                <!-- upload image -->
                <div>
                  <input
                    type="file"
                    style="display: none"
                    ref="imageInput"
                    accept="*/*"
                    @change="handleFileUpload"
                  />

                  <v-btn
                    @click.stop="startPickImage"
                    class="ml-2"
                    color="teal"
                    outlined
                    style="min-width:20px;width:46px;"
                  >
                    <v-icon>
                      add_photo_alternate
                    </v-icon>
                  </v-btn>
                </div>
                <!-- list of image -->
                <div class="py-2">
                  <template v-for="(img, imgIdx) in car.images">
                    <div
                      class="pa-1 mr-2 mb-2"
                      :key="'img' + imgIdx"
                      style="vertical-align: top;position:relative;display: inline-block;min-height: 80px;max-height: 80px;min-width: 130px;max-width: 130px;border:1px solid #DDDDDD;"
                    >
                      <div
                        @click.stop="removeImage(imgIdx)"
                        style="position:absolute;top:-10px;right:-5px;z-index:2;cursor:pointer;"
                      >
                        <v-icon color="red" style="font-size:20px;"
                          >mdi-close-circle-outline</v-icon
                        >
                      </div>

                      <div
                        style="width:100%;height:70px;z-index:1; overflow-y:hidden;cursor:pointer;"
                        @click.stop="showImageFullScreen(img)"
                      >
                        <img
                          :src="img"
                          style="object-fit:cover;width:100%;height:100%;"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div class="mt-2">
                Mô tả:
              </div>
              <div class="description">
                <v-textarea
                  flat
                  dense
                  v-model="car.description"
                  :rules="[]"
                  color="teal"
                  :clearable = "$vuetify.breakpoint.smAndDown"
                  outlined
                  filled auto-grow
                  hide-details
                ></v-textarea>
              </div>
            </div>
          </v-card-text>
          <v-card-actions 
            v-if="car"
            class="pr-4">
            <v-spacer/>
            <v-btn
                class="my-1"
                style="min-width:160px;"
                color="#AD1457" dark
                :loading="saveLoading"
                @click.stop="callSaveCar">
                Lưu
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <show-full-image
      :show="showFullImage"
      :image="imgFullScreen"
      @close-dialog-show-fullimage="showFullImage = false"
    ></show-full-image>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      token: "token",
    }),
    displayDeptAfterFilter() {
      let result = null
      if (!this.car) return null
      result = JSON.parse(JSON.stringify(this.car))
      return result
    },
  },
  watch:{
    '$route.params'(){
      console.log('watch: ',this.$route.params.cars_id)
      if(this.$route.params.cars_id){
        if(this.$route.params.cars_id == '0') {
          this.car = {
            name: '',
            owner: 'MCV',
            num_of_seat: "",
            plate: "",
            phone: "",
            description: '',
            images: [],
            code: '',
            type: 'car',
            enable: true
          } 
          this.$forceUpdate();
          this.$refs.name.reset()
          this.$refs.plate.reset()
        } else {
          this.fetchCar()
        }
      }
    }
  },
  data() {
    return {
      roles: ['vehicles-manager', 'admin'],
      car: null,
      saveLoading: false,
      dataBeforeSave: null,
      showFullImage: false,
      imgFullScreen: "",
      rules: {
        required: (value) => !!value || "* Required",
        passRule: (v) => (v && v.length >= 6) || "Min 6 chars",
        username: (v) => (v && v.length >= 6) || "Min 6 chars",
        fullname: (v) => (v && v.length >= 3) || "Min 3 chars",
        email: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      },
    };
  },
  methods: {
    goBack () {
      // this.$router.go(-1)
      this.$router.push({path: '/cars'})
    },
    showImageFullScreen(link) {
      this.showFullImage = true;
      this.imgFullScreen = link;
    },
    removeImage(p) {
      this.car.images.splice(p, 1);
    },
    startPickImage() {
      this.$refs.imageInput.click();
    },
    handleFileUpload: function() {
      var file = this.$refs.imageInput.files[0];
      if (file !== undefined) {
        var self = this;
        self.result = "";
        let formData = new FormData();
        formData.append("file", file);
        self.axios
          .post("https://file.ponotek.com/uploads", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(res) {
            console.log("Upload image:", res.data);
            self.car.images.push(res.data.content);
          })
          .catch(function(err) {
            console.log("Err upload:", err);
          });
      }
    },
    createCarCode (name) {
      return name.normalize("NFD")
                                 .replace(/[\u0300-\u036f]/g, "")
                                 .replace(/đ/g, "d")
                                 .replace(/Đ/g, "D")
                                 .replace(/\s+/g, '-')
                                 .toUpperCase() + "-"+ new Date().getTime()
    },
    callCreateCar () {
      let self = this
      this.car.code = "MCV-Car-" + self.createCarCode(self.car.name) + '-' + Date.now();
      this.car.type = 'car'
      this.saveLoading = true

      this.axios
        .post(this.$root.apiAssetMana + "/cars",
              self.car,
              { headers: { "x-auth": self.token } })
        .then((res) => {
          if (res.data.status === "OK") {
            alert("Tạo xe " + self.car.name + " thành công!");
            self.$router.push({path: '/cars-detail/' + res.data.content._id})
          } else {
            alert("Lỗi: " + res.data.message);
          }
        })
        .catch((err) => {
          alert("Lỗi: " + err.message);
        }).finally(()  => {
          self.saveLoading = false
        })
    },
    callSaveCar() {
      if (!this.car) return

      // if (!this.car.code || this.car.code.length <= 0) {
      //   alert("Mã xe không được để trống!");
      //   return;
      // }
      if (!this.car.name || this.car.name.length <= 0) {
        alert("Tên xe không được để trống!");
        return;
      }
      if (!this.car.plate || this.car.plate.length <= 0) {
        alert("Biển số xe không được để trống!");
        return;
      }

      if(!this.car._id) {
        this.callCreateCar()
        return
      }
      this.saveLoading = true

      let self = this
      this.axios
        .post(this.$root.apiAssetMana + "/cars", self.car, {
          headers: {
            "x-auth": this.token,
          },
        })
        .then((response) => {
          if (response.data.status == "OK") {
            self.car = response.data.content;
            alert("Cập nhật thành công!");
          } else {
            alert("Lỗi! " + response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        }).finally(()  => {
          self.saveLoading = false
        })
    },
    fetchCar() {
      let self = this;
      self.axios
        .get(
          self.$root.apiAssetMana +
            "/cars/" +
            this.$route.params.cars_id,
          { headers: { "x-auth": this.token }}
        )
        .then((res) => {
          if (res.data.status == "OK") {
            self.car = res.data.content;
            console.log(self.car)
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if(this.$route.params.cars_id){
        if(this.$route.params.cars_id == '0') {
          this.car = {
            name: '',
            owner: 'MCV',
            num_of_seat: "",
            plate: "",
            phone: "",
            description: '',
            images: [],
            code: '',
            type: 'car',
            enable: true
          } 
          console.log("new car:", this.car)
          this.$forceUpdate();
        } else {
          this.fetchCar()
        }
      }
    })
  },
};
</script>

<style>
  .description textarea {
    margin-bottom: 10px !important;
    margin-right: 10px !important;
    line-height: 1.35;
    font-size: 14px;
  }
</style>
